import React from 'react';
import './ImageGrid.css';
import arrowRight from './ArrowRight.svg';
import arrowLeft from './ArrowLeft.svg';

class ImageView extends React.Component {

    constructor(props) {
        super(props);
        this.keyListener = this.keyListener.bind(this)
    }

    componentDidMount(){
        document.addEventListener("keydown", this.keyListener, false);

    }

    componentWillUnmount(){
        document.removeEventListener("keydown", this.keyListener, false);
    }

    keyListener(e){
        switch (e.code) {
            case "ArrowLeft":
                this.props.previousImage(e, this.props.image);
                break;
            case "ArrowRight":
                this.props.nextImage(e, this.props.image);
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div className="ImageViewBackground" onClick={(e) => this.props.toggleImage(e, this.props.image)}>
                <div className="ImageViewImageWrapper">
                    <div id="ImageViewImage">
                        <div id="ImageViewImageLeft" onClick={(e) => this.props.previousImage(e, this.props.image)}>
                            <img src={arrowLeft} alt={"Arrow pointing left"}></img></div>
                        <img src={this.props.image.original} onClick={() => {}}></img>
                        <div id="ImageViewImageRight" onClick={(e) => this.props.nextImage(e, this.props.image)}>
                            <img src={arrowRight} alt={"Arrow pointing right"}></img>
                        </div>
                        {this.props.image.description !== ""  && <div id="ImageViewImageDescription">{this.props.image.description}</div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default ImageView;
