import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';
import Travels from './components/travels/Travels';
import Gallery from "./components/gallery/Gallery";

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
      <Router>
          <div>
              <Routes>
                  <Route exact path="/" element={<Travels />} />
                  <Route exact path="/gallery" element={<Gallery />} />
                  <Route exact path="/gallery/:destination" element={<Gallery />} />
              </Routes>
          </div>
      </Router>
  </React.StrictMode>
);
