import React from 'react';
import './TravelDestinations.css';
import {Link} from "react-router-dom";

class TravelDestinations extends React.Component {

    constructor(props) {
        super(props);
        this.state = { width: 0, height: 0 };
        this.destinationData = [
            {
                key: 'Singapore',
                id: 1,
                group: 1,
                xOffset: -1350,
                yOffset: -280
            },
            {
                key: 'Japan',
                id: 2,
                group: 1,
                xOffset: -1150,
                yOffset: -220
            },
            {
                key: 'Ireland',
                id: 3,
                group: 1,
                xOffset: -1030,
                yOffset: -310
            },
            {
                key: 'Myanmar',
                id: 4,
                group: 1,
                xOffset: -920,
                yOffset: -440
            },
            {
                key: 'New Zealand',
                id: 5,
                group: 1,
                xOffset: -830,
                yOffset: -600
            },
            {
                key: 'Iceland',
                id: 6,
                group: 2,
                xOffset: -880,
                yOffset: 480
            },
            {
                key: 'Taiwan',
                id: 7,
                group: 2,
                xOffset: -760,
                yOffset: 560
            },
            {
                key: 'China',
                id: 8,
                group: 2,
                xOffset: -640,
                yOffset: 640
            },
            {
                key: 'Cambodia',
                id: 9,
                group: 2,
                xOffset: -580,
                yOffset: 780
            }
        ];
        this.destinationMobileData = [
            {
                key: 'Singapore',
                id: 1,
                group: 1,
                xOffset: 300,
                yOffset: 400
            },
            {
                key: 'Japan',
                id: 2,
                group: 1,
                xOffset: 0,
                yOffset: 0
            },
            {
                key: 'Ireland',
                id: 3,
                group: 1,
                xOffset: -300,
                yOffset: -100
            },
            {
                key: 'Myanmar',
                id: 4,
                group: 1,
                xOffset: -400,
                yOffset: 300
            },
            {
                key: 'New Zealand',
                id: 5,
                group: 1,
                xOffset: 0,
                yOffset: 650
            },
            {
                key: 'Iceland',
                id: 6,
                group: 2,
                xOffset: 400,
                yOffset: 100
            },
            {
                key: 'Taiwan',
                id: 7,
                group: 2,
                xOffset: 500,
                yOffset: -100
            },
            {
                key: 'China',
                id: 8,
                group: 2,
                xOffset: 100,
                yOffset: 200
            },
            {
                key: 'Cambodia',
                id: 9,
                group: 2,
                xOffset: -300,
                yOffset: 500
            }
        ];
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.onResize();
    }

    onResize = () => {
        let usedDestinationData = window.innerWidth <= 850 ? this.destinationMobileData : this.destinationData;
        usedDestinationData.forEach(function(destination) {
            let element = document.getElementById(destination.key);
            let elementWrapper = document.getElementById(destination.key+'Wrapper');
            let newX;
            let newY;
            if(window.innerWidth/window.innerHeight > 1.5){
                newX = (destination.xOffset/5184)*window.innerWidth;
                newY = (destination.yOffset/5184)*window.innerWidth;
                element.style.fontSize = '1.5vw';
            } else {
                newX = (destination.xOffset/3456)*window.innerHeight;
                newY = (destination.yOffset/3456)*window.innerHeight;
                element.style.fontSize = '2.25vh';
            }
            element.style.left = ((window.innerWidth/2)+newX) + 'px';
            element.style.top = ((window.innerHeight/2)+newY) + 'px';
            if (window.innerWidth > 850 && destination.id <= 5) {
                document.getElementById(destination.key+'Wrapper').style.paddingLeft = document.getElementById(destination.key+'Inner').offsetWidth * 0.95 + 'px';
            } else if (window.innerWidth <= 850){
                document.getElementById(destination.key+'Wrapper').style.paddingLeft = 0 + 'px';
            }
            setTimeout(() => {
                elementWrapper.classList.add('travelDestinationLabelWrapperAnimation');
            }, 400);
        });
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    onDestinationHover = (key, e) => {
        let foundElementWrapper = document.getElementById(key+'Wrapper');
        let destination = this.destinationData.find(element => element.key === key);
        if (window.innerWidth <= 850) {
            foundElementWrapper.classList.add('travelDestinationLabelWrapperOnHover');
        } else if (destination.id <= 5) {
            foundElementWrapper.style.paddingLeft = 0 + 'px';
            foundElementWrapper.style.paddingRight = document.getElementById(key+'Inner').offsetWidth * 0.95 + 'px';
        } else {
            foundElementWrapper.style.paddingLeft = document.getElementById(key+'Inner').offsetWidth * 0.9 + 'px';
        }
    }

    onDestinationLeave = (key, e) => {
        let foundElement = document.getElementById(key);
        let foundElementWrapper = document.getElementById(key+'Wrapper');
        let destination = this.destinationData.find(element => element.key === key);
        if (window.innerWidth <= 850) {
            foundElementWrapper.classList.remove('travelDestinationLabelWrapperOnHover');
        } else if (destination.id <= 5) {
            foundElementWrapper.style.paddingLeft = document.getElementById(key+'Inner').offsetWidth * 0.95 + 'px';
            foundElementWrapper.style.paddingRight = 0 + 'px';
        } else {
            foundElementWrapper.style.paddingLeft = 0 + 'px';
        }
        if(window.innerWidth/window.innerHeight > 1.5){
            foundElement.style.fontSize = '1.5vw';
        } else {
            foundElement.style.fontSize = '2.25vh';
        }
    }

    render() {
        return (
            <div id='travelsDestinations'>
                {this.destinationData &&
                    this.destinationData.map((destination) => (
                        <Link to={'../gallery/'+destination.key} key={destination.key}>
                            <div id={destination.key} className={'travelDestinationLabel travelDestinationLabel' + destination.group}>
                                <div id={destination.key+'Wrapper'}
                                     className={'travelDestinationLabelWrapper travelDestinationLabelWrapper' + destination.group}
                                     onMouseOver={(e) => {
                                         this.onDestinationHover(destination.key, e)
                                     }}
                                     onMouseOut={(e) => {
                                         this.onDestinationLeave(destination.key, e)
                                     }}>
                                    <div id={destination.key+"Inner"}>
                                        {destination.key}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}
            </div>
        );
    }
}

export default TravelDestinations;
